import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
// import Outsourced from "../images/outsourced-it-support-services.svg";
// import Managed from "../images/managed-offsite-data-backup.svg";
// import WhiteLabel from "../images/white-label-it-projects.svg";
// import OnsiteComputer from "../images/onsite-computer-repairs.svg";
// import NetworkingSolutions from "../images/networking-solutions.svg";
// import HomeBusiness from "../images/home-business-antivirus.svg";
// import NAS from "../images/nas-storage-server.svg";
// import Technology from "../images/technology-hardware-suppliers.svg";
// import Consultancy from "../images/consultancy-recommendations.svg";
// import Refurbished from "../images/refurbished-computing.svg";
import Layout from "../components/layout";
import { Col, Container, Row } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import ReviewSection from "../components/review-section";
import Lenovo from "../images/Lenovo.svg";
import ContactForm from "../components/contactForm";
import ServicePlans from "../components/service-plans";

const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Cranfield IT Response Car" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      pageData: wpPage(slug: { eq: "services" }) {
        pageFlexibleContent {
          pageFlexibleContent {
            ... on WpPage_Pageflexiblecontent_PageFlexibleContent_InnerPageBanner {
              fieldGroupName
              innerPageBannerHeading
              innerPageBannerSubHeading
              innerPageLink {
                target
                title
                url
              }
              innerPageBannerImage {
                sourceUrl
              }
            }
            ... on WpPage_Pageflexiblecontent_PageFlexibleContent_AllServices {
              fieldGroupName
              serviceTitle
              services {
                serviceHeading
                serviceSubHeading
                serviceIcon {
                  sourceUrl
                  altText
                }
              }
            }
            ... on WpPage_Pageflexiblecontent_PageFlexibleContent_TechnologyPartners {
              fieldGroupName
              technologyPartnersHeading
              technologyPartners {
                technologyPartnerUrl
                technologyPartnerLogo {
                  altText
                  sourceUrl
                }
              }
            }
            ... on WpPage_Pageflexiblecontent_PageFlexibleContent_GetInTouch {
              fieldGroupName
              getInTouchHeading
            }
            ... on WpPage_Pageflexiblecontent_PageFlexibleContent_ServicePlans {
              fieldGroupName
              servicePlanDescription
              servicePlanTitle
              serviceContactLink {
                target
                title
                url
              }
              servicePlanItems {
                content
                fieldGroupName
                title
                icon {
                  sourceUrl
                  altText
                }
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  // const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Services",
        item: {
          url: `${siteUrl}/services`,
          id: `${siteUrl}/services`,
        },
      },
    ],
  };

  const getInTouch = () => {
    let heading = "Get in touch to find out how we can work together";
    if (pageFlexibleContent.length > 0) {
      const reviewSection = pageFlexibleContent.filter(
        (item) =>
          item.fieldGroupName ==
          "Page_Pageflexiblecontent_PageFlexibleContent_GetInTouch"
      );
      if (reviewSection.length > 0) {
        heading = reviewSection[0].getInTouchHeading;
      }
    }
    return (
      <section style={{ zIndex: 3 }} className="pb-5 py-lg-7 position-relative">
        <Container>
          <Row>
            <Col>
              <h2 className="fs-1 text-center mb-cl-5 mb-4">{heading}</h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={8}>
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </section>
    );
  };

  const technologyPartner = () => {
    if (pageFlexibleContent.length > 0) {
      const reviewSection = pageFlexibleContent.filter(
        (item) =>
          item.fieldGroupName ==
          "Page_Pageflexiblecontent_PageFlexibleContent_TechnologyPartners"
      );

      if (reviewSection.length > 0) {
        return (
          <section
            style={{ zIndex: 3 }}
            className="py-5 py-lg-7 position-relative"
          >
            <Container>
              <Row>
                <Col>
                  <h2 className="fs-1 mb-5 mb-lg-6 text-center">
                    {reviewSection[0].technologyPartnersHeading}
                  </h2>
                </Col>
              </Row>
              {reviewSection[0].technologyPartners.length > 0 && (
                <Row className="align-items-center g-5">
                  {reviewSection[0].technologyPartners.map((techItem, i) => (
                    <Col
                      className="text-center"
                      xs={4}
                      lg={i % 2 == 0 ? 2 : true}
                      key={i}
                    >
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={techItem.technologyPartnerUrl}
                      >
                        <img
                          src={techItem?.technologyPartnerLogo.sourceUrl}
                          alt={techItem?.technologyPartnerLogo?.altText}
                          className="mw-100"
                        />
                      </a>
                    </Col>
                  ))}
                </Row>
              )}
            </Container>
          </section>
        );
      }
    }
  };

  const {
    pageData: {
      pageFlexibleContent: { pageFlexibleContent },
    },
  } = data;

  return (
    <React.Fragment>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title="Services | Cranfield IT Solutions: Computer Repair & Tech Support Sussex"
        description="Computer Repair & Tech Support."
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/services`,
          title: "Services | Cranfield IT Solutions: Computer Repair & Tech Support Sussex",
          description: "Computer Repair & Tech Support.",
          images: [
            {
              url: `${data.heroImg?.localFile.publicURL}`,
              width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
              height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
              alt: `${data.heroImg?.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {pageFlexibleContent.length > 0 &&
          pageFlexibleContent.map((pageInfo, i) => (
            <>
              {pageInfo.fieldGroupName ==
                "Page_Pageflexiblecontent_PageFlexibleContent_InnerPageBanner" && (
                <Hero
                  title={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: pageInfo.innerPageBannerHeading,
                      }}
                    ></span>
                  }
                  subHeading={pageInfo.innerPageBannerSubHeading}
                  buttonTarget={pageInfo.innerPageLink?.target}
                  buttonLink={pageInfo.innerPageLink?.url}
                  buttonTitle={pageInfo.innerPageLink?.title}
                  bannerImage={pageInfo.innerPageBannerImage.sourceUrl}
                  hideButton={!pageInfo.innerPageLink ?? true}
                  key={i}
                />
              )}
              {pageInfo.fieldGroupName ==
                "Page_Pageflexiblecontent_PageFlexibleContent_AllServices" && (
                <section className="pb-5 py-lg-7">
                  <Container>
                    <Row className="justify-content-center mb-6">
                      <Col md={8}>
                        <h2 className="fs-1 text-center">
                          {pageInfo?.serviceTitle}
                        </h2>
                      </Col>
                    </Row>
                    {pageInfo.services.length > 0 && (
                      <Row className="g-4">
                        {pageInfo.services.map((serviceInfo, i) => (
                          <Col lg={6}>
                            <div
                              style={{
                                zIndex: 2,
                                borderRadius: "4px",
                                border: "1px solid #4FC4D8",
                              }}
                              className="bg-black py-2 px-xl-4 px-3 mb-3 d-flex justify-content-center h-100 align-items-center"
                            >
                              <div className="flex-grow-1 ">
                                <div className="d-flex gap-4 h-100">
                                  <img
                                    alt={serviceInfo.serviceIcon?.altText}
                                    src={serviceInfo.serviceIcon.sourceUrl}
                                  />
                                  <h3
                                    style={{ lineHeight: "180%" }}
                                    className="roboto-regular fs-5"
                                  >
                                    <span
                                      className="roboto-bold"
                                      style={{ color: "#4FC4D8" }}
                                    >
                                      {serviceInfo.serviceSubHeading}
                                    </span>{" "}
                                    <br />
                                    {serviceInfo.serviceHeading}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    )}
                  </Container>
                </section>
              )}
              {pageInfo.fieldGroupName ==
                "Page_Pageflexiblecontent_PageFlexibleContent_ServicePlans" && (
                <ServicePlans
                  title={pageInfo?.servicePlanTitle}
                  content={pageInfo?.servicePlanDescription}
                  items={pageInfo?.servicePlanItems}
                  button={pageInfo?.serviceContactLink}
                />
              )}
            </>
          ))}

        <section className=" pt-lg-7">
          <ReviewSection />
        </section>
        {technologyPartner()}
        {getInTouch()}
      </Layout>
    </React.Fragment>
  );
};

export default ServicesPage;

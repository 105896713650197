import React, { useEffect, useState } from "react";
import { Container, Row, Spinner, Col, Button } from "react-bootstrap";
import parse from "html-react-parser";
import { Link } from "gatsby";

const ServicePlans = ({ title, content, items, button }) => {
  return (
    <section className="pb-5 py-lg-7">
      <Container>
        <Row className="justify-content-center mb-5">
          <Col md={8}>
            <h2 className="fs-1 text-center">{title}</h2>
            {content && (
              <div className="service-content text-center mt-4">
                {parse(content)}
              </div>
            )}
          </Col>
        </Row>
        {items.length > 0 && (
          <div className="grid-container d-grid gap-4">
            {items.map((serviceInfo, i) => (
              <div className="grid-item rounded-4 bg-schema-grey p-4 mb-3 h-100">
                <div className="flex-grow-1 ">
                  <div className="gap-4 h-100">
                    <div
                      className="border-primary border rounded-circle d-flex align-items-center justify-content-center mb-3"
                      style={{ height: "64px", width: "64px" }}
                    >
                      <img
                        alt={serviceInfo.icon?.altText}
                        src={serviceInfo.icon.sourceUrl}
                      />
                    </div>
                    <h3
                      style={{ lineHeight: "180%" }}
                      className="roboto-semibold fs-5"
                    >
                      {serviceInfo.title}
                    </h3>
                    {serviceInfo?.content && (
                      <div className="service-content">
                        {parse(serviceInfo.content)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {button && button.url && (
          <div className="text-center">
            <Button
              variant="primary"
              className="px-5 mt-6 py-3 w-100 w-md-auto"
              type="submit"
              as={Link}
              href={button?.url}
            >
              {button?.title}
            </Button>
          </div>
        )}
      </Container>
    </section>
  );
};

export default ServicePlans;
